import { UiProvider } from "@lgastler-ba/ui";
import { Link } from "gatsby";
import React, { Fragment, ReactNode } from "react";

export function WrapRoot({ children }: { children: ReactNode }) {
  return (
    <UiProvider value={{ framework: "gatsby", linkComponent: Link }}>
      {children}
    </UiProvider>
  );
}
