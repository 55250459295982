var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import React, { createContext, useState, Fragment as Fragment$1 } from "react";
import { Transition, Dialog, Tab, Popover } from "@headlessui/react";
import { XIcon, MenuIcon, SearchIcon, ShoppingCartIcon, EmojiSadIcon } from "@heroicons/react/outline";
import { SearchIcon as SearchIcon$1 } from "@heroicons/react/solid";
var global = "";
function MainLayout() {
  return /* @__PURE__ */ jsxs(Fragment, {
    children: [/* @__PURE__ */ jsx("header", {
      children: "Header"
    }), /* @__PURE__ */ jsx("main", {
      children: "Main"
    }), /* @__PURE__ */ jsx("footer", {
      children: "Footer"
    })]
  });
}
const CountContext = createContext({});
function UiProvider({
  children,
  value
}) {
  return /* @__PURE__ */ jsx(CountContext.Provider, {
    value,
    children
  });
}
function useUiContext() {
  const context = React.useContext(CountContext);
  if (context === void 0) {
    throw new Error("useUiContext must be used within a UiProvider");
  }
  return context;
}
function Link(_a) {
  var _b = _a, {
    href,
    children,
    className
  } = _b, rest = __objRest(_b, [
    "href",
    "children",
    "className"
  ]);
  const DefaultLink = /* @__PURE__ */ jsx("a", __spreadProps(__spreadValues({
    href,
    className
  }, rest), {
    id: "a-link",
    children
  }));
  const {
    linkComponent: RouterLink,
    framework
  } = useUiContext();
  if (RouterLink && framework === "next") {
    return /* @__PURE__ */ jsx(RouterLink, __spreadProps(__spreadValues({
      href
    }, rest), {
      children: /* @__PURE__ */ jsx("a", {
        className,
        children
      })
    }));
  }
  if (RouterLink && framework === "remix") {
    return /* @__PURE__ */ jsx(RouterLink, __spreadProps(__spreadValues({
      to: href,
      className,
      prefetch: "intent"
    }, rest), {
      children
    }));
  }
  if (RouterLink && framework === "gatsby") {
    return /* @__PURE__ */ jsx(RouterLink, __spreadProps(__spreadValues({
      to: href,
      className
    }, rest), {
      children
    }));
  }
  if (RouterLink) {
    return /* @__PURE__ */ jsx(RouterLink, __spreadProps(__spreadValues({
      href,
      className
    }, rest), {
      children
    }));
  }
  return DefaultLink;
}
function ProductList({
  products
}) {
  return /* @__PURE__ */ jsx("div", {
    className: "bg-white",
    children: /* @__PURE__ */ jsxs("div", {
      className: "py-16 sm:py-24 lg:mx-auto lg:max-w-7xl lg:px-8",
      children: [/* @__PURE__ */ jsx("div", {
        className: "flex items-center justify-between px-4 sm:px-6 lg:px-0",
        children: /* @__PURE__ */ jsx("h2", {
          className: "text-2xl font-extrabold tracking-tight text-gray-900",
          children: "Some of our Products"
        })
      }), /* @__PURE__ */ jsx("div", {
        className: "relative mt-8",
        children: /* @__PURE__ */ jsx("div", {
          className: "relative w-full pb-6 -mb-6 overflow-x-auto",
          children: /* @__PURE__ */ jsx("ul", {
            "data-testid": "product-list",
            role: "list",
            className: "inline-flex mx-4 space-x-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0",
            children: products.map((product) => {
              var _a, _b;
              return /* @__PURE__ */ jsx("li", {
                className: "inline-flex flex-col w-64 text-center lg:w-auto p-2",
                children: /* @__PURE__ */ jsxs("div", {
                  className: "relative group",
                  children: [/* @__PURE__ */ jsx("div", {
                    className: "w-full overflow-hidden bg-gray-200 rounded-md aspect-w-1 aspect-h-1",
                    children: /* @__PURE__ */ jsx("img", {
                      loading: "lazy",
                      src: product.imageSrc,
                      alt: product.imageAlt,
                      width: (_a = product.imageWidth) != null ? _a : void 0,
                      height: (_b = product.imageHeight) != null ? _b : void 0,
                      className: "object-cover object-center w-full h-full group-hover:opacity-75"
                    })
                  }), /* @__PURE__ */ jsxs("div", {
                    className: "mt-6",
                    children: [/* @__PURE__ */ jsx("h3", {
                      className: "mt-1 font-semibold text-gray-900",
                      children: /* @__PURE__ */ jsxs(Link, {
                        href: `product/${product.slug}`,
                        children: [/* @__PURE__ */ jsx("span", {
                          className: "absolute inset-0"
                        }), product.name]
                      })
                    }), /* @__PURE__ */ jsx("p", {
                      className: "mt-1 text-gray-900",
                      children: product.price
                    })]
                  })]
                })
              }, product.id);
            })
          })
        })
      })]
    })
  });
}
function LoadingSpinner() {
  return /* @__PURE__ */ jsxs("svg", {
    className: "w-8 h-8 mr-3 -ml-1 text-blue-500 animate-spin",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    children: [/* @__PURE__ */ jsx("circle", {
      className: "opacity-25",
      cx: 12,
      cy: 12,
      r: 10,
      stroke: "currentColor",
      strokeWidth: 4
    }), /* @__PURE__ */ jsx("path", {
      className: "opacity-75",
      fill: "currentColor",
      d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    })]
  });
}
function Tweet({
  tweet,
  trends
}) {
  var _a;
  return /* @__PURE__ */ jsx(Fragment, {
    children: /* @__PURE__ */ jsx("div", {
      className: "relative flex flex-col min-h-screen",
      children: /* @__PURE__ */ jsxs("div", {
        className: "flex-grow w-full mx-auto max-w-7xl xl:px-8 lg:flex",
        children: [/* @__PURE__ */ jsxs("div", {
          className: "flex-1 min-w-0 bg-white xl:flex",
          children: [/* @__PURE__ */ jsx("div", {
            className: "bg-white border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200",
            children: /* @__PURE__ */ jsx("div", {
              className: "py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0",
              children: /* @__PURE__ */ jsx("div", {
                className: "relative",
                children: /* @__PURE__ */ jsx("h1", {
                  className: "text-4xl font-extrabold",
                  children: "#chape"
                })
              })
            })
          }), /* @__PURE__ */ jsx("div", {
            className: "bg-white lg:min-w-0 lg:flex-1",
            children: /* @__PURE__ */ jsx("div", {
              className: "h-full px-4 py-6 sm:px-6 lg:px-8",
              children: /* @__PURE__ */ jsxs("div", {
                className: "space-y-4",
                children: [tweet ? /* @__PURE__ */ jsxs("div", {
                  className: "flex space-x-2 user-wrapper",
                  children: [/* @__PURE__ */ jsx("img", {
                    className: "w-12 h-12 rounded-full",
                    src: tweet.author.profileImageUrl,
                    alt: tweet.author.name
                  }), /* @__PURE__ */ jsxs("div", {
                    className: "flex flex-col",
                    children: [/* @__PURE__ */ jsx("span", {
                      className: "font-bold",
                      children: tweet.author.name
                    }), /* @__PURE__ */ jsxs("span", {
                      children: ["@", tweet.author.username]
                    })]
                  })]
                }) : /* @__PURE__ */ jsxs("div", {
                  className: "flex space-x-2 animate-pulse",
                  children: [/* @__PURE__ */ jsx("div", {
                    className: "w-12 h-12 rounded-full bg-slate-200"
                  }), /* @__PURE__ */ jsxs("div", {
                    className: "flex flex-col h-full space-y-1 jusitfy-center",
                    children: [/* @__PURE__ */ jsx("div", {
                      className: "w-32 h-4 rounded bg-slate-200"
                    }), /* @__PURE__ */ jsx("div", {
                      className: "w-16 h-4 rounded bg-slate-200"
                    })]
                  })]
                }), tweet ? /* @__PURE__ */ jsx("div", {
                  className: "pt-2 prose prose-xl content",
                  children: /* @__PURE__ */ jsx("p", {
                    children: tweet.text
                  })
                }) : /* @__PURE__ */ jsxs("div", {
                  className: "space-y-2 animate-pulse",
                  children: [/* @__PURE__ */ jsx("div", {
                    className: "w-full h-4 rounded bg-slate-200"
                  }), /* @__PURE__ */ jsx("div", {
                    className: "w-full h-4 rounded bg-slate-200"
                  }), /* @__PURE__ */ jsx("div", {
                    className: "w-full h-4 rounded bg-slate-200"
                  }), /* @__PURE__ */ jsx("div", {
                    className: "w-3/4 h-4 rounded bg-slate-200"
                  })]
                }), (tweet == null ? void 0 : tweet.media) ? /* @__PURE__ */ jsx("div", {
                  className: "m-2 img-container",
                  children: (_a = tweet.media) == null ? void 0 : _a.map((media) => /* @__PURE__ */ jsx("img", {
                    src: media.url,
                    alt: "",
                    className: "object-cover shadow rounded-xl "
                  }, media.url))
                }) : null, tweet ? /* @__PURE__ */ jsxs("div", {
                  className: "space-x-2",
                  children: [/* @__PURE__ */ jsx("span", {
                    children: new Date(tweet.createdAt).toLocaleTimeString(void 0, {
                      hour: "2-digit",
                      minute: "2-digit"
                    })
                  }), /* @__PURE__ */ jsx("span", {
                    children: "\u2022"
                  }), /* @__PURE__ */ jsx("span", {
                    children: new Date(tweet.createdAt).toLocaleDateString()
                  }), /* @__PURE__ */ jsx("span", {
                    children: "\u2022"
                  }), /* @__PURE__ */ jsx("span", {
                    children: tweet.source
                  })]
                }) : null, tweet ? /* @__PURE__ */ jsxs("div", {
                  className: "py-2 space-x-3 border-gray-200 border-y",
                  children: [/* @__PURE__ */ jsxs("span", {
                    children: [/* @__PURE__ */ jsx("strong", {
                      children: "4"
                    }), " Retweets"]
                  }), /* @__PURE__ */ jsxs("span", {
                    children: [/* @__PURE__ */ jsx("strong", {
                      children: "2"
                    }), " Quote Tweets"]
                  }), /* @__PURE__ */ jsxs("span", {
                    children: [/* @__PURE__ */ jsx("strong", {
                      children: "4"
                    }), " Likes"]
                  })]
                }) : null]
              })
            })
          })]
        }), /* @__PURE__ */ jsx("div", {
          className: "px-4 sm:pr-6 lg:pr-8 lg:py-4 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0",
          children: /* @__PURE__ */ jsx("div", {
            className: "h-full lg:w-80",
            children: /* @__PURE__ */ jsx("div", {
              className: "relative h-full bg-gray-100 rounded-md",
              style: {
                minHeight: "16rem"
              },
              children: /* @__PURE__ */ jsxs(Fragment, {
                children: [/* @__PURE__ */ jsx("h2", {
                  className: "px-4 py-5 text-2xl font-bold text-slate-900",
                  children: "Trends"
                }), trends ? /* @__PURE__ */ jsx(Fragment, {
                  children: trends.map((trend) => /* @__PURE__ */ jsxs("div", {
                    className: `group relative px-4 py-3 hover:bg-gray-200 ${trend.url ? "cursor-pointer" : null}`,
                    children: [/* @__PURE__ */ jsx("span", {
                      className: "text-sm font-light",
                      children: trend.subtitle
                    }), /* @__PURE__ */ jsx("h3", {
                      className: `text-lg font-bold ${trend.url ? "group-hover:underline" : null}`,
                      children: trend.title
                    }), trend.amount ? /* @__PURE__ */ jsx("span", {
                      className: "text-sm font-light",
                      children: trend.amount
                    }) : null, /* @__PURE__ */ jsx("a", {
                      href: trend.url,
                      target: "_blank",
                      className: "absolute inset-0"
                    })]
                  }, trend.id))
                }) : /* @__PURE__ */ jsx("div", {
                  className: "flex justify-center w-full",
                  children: /* @__PURE__ */ jsx(LoadingSpinner, {})
                })]
              })
            })
          })
        })]
      })
    })
  });
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const currencies = ["EUR", "USD"];
const navigation$1 = {
  categories: void 0,
  pages: [{
    name: "Fashion",
    href: "/search?category=fashion"
  }, {
    name: "Home & Garden",
    href: "/search?category=home-garden"
  }]
};
function StoreHeader({
  cartAmount
}) {
  var _a, _b, _c, _d, _e;
  const [open, setOpen] = useState(false);
  return /* @__PURE__ */ jsxs("div", {
    className: "bg-white",
    children: [/* @__PURE__ */ jsx(Transition.Root, {
      show: open,
      as: Fragment$1,
      children: /* @__PURE__ */ jsxs(Dialog, {
        as: "div",
        className: "fixed inset-0 z-40 flex lg:hidden",
        onClose: setOpen,
        children: [/* @__PURE__ */ jsx(Transition.Child, {
          as: Fragment$1,
          enter: "transition-opacity ease-linear duration-300",
          enterFrom: "opacity-0",
          enterTo: "opacity-100",
          leave: "transition-opacity ease-linear duration-300",
          leaveFrom: "opacity-100",
          leaveTo: "opacity-0",
          children: /* @__PURE__ */ jsx(Dialog.Overlay, {
            className: "fixed inset-0 bg-black bg-opacity-25"
          })
        }), /* @__PURE__ */ jsx(Transition.Child, {
          as: Fragment$1,
          enter: "transition ease-in-out duration-300 transform",
          enterFrom: "-translate-x-full",
          enterTo: "translate-x-0",
          leave: "transition ease-in-out duration-300 transform",
          leaveFrom: "translate-x-0",
          leaveTo: "-translate-x-full",
          children: /* @__PURE__ */ jsxs("div", {
            className: "relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl",
            children: [/* @__PURE__ */ jsx("div", {
              className: "flex px-4 pt-5 pb-2",
              children: /* @__PURE__ */ jsxs("button", {
                type: "button",
                className: "inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md",
                onClick: () => setOpen(false),
                children: [/* @__PURE__ */ jsx("span", {
                  className: "sr-only",
                  children: "Close menu"
                }), /* @__PURE__ */ jsx(XIcon, {
                  className: "w-6 h-6",
                  "aria-hidden": "true"
                })]
              })
            }), /* @__PURE__ */ jsxs(Tab.Group, {
              as: "div",
              className: "mt-2",
              children: [/* @__PURE__ */ jsx("div", {
                className: "border-b border-gray-200",
                children: /* @__PURE__ */ jsx(Tab.List, {
                  className: "flex px-4 -mb-px space-x-8",
                  children: (_a = navigation$1.categories) == null ? void 0 : _a.map((category) => /* @__PURE__ */ jsx(Tab, {
                    className: ({
                      selected
                    }) => classNames(selected ? "border-indigo-600 text-indigo-600" : "border-transparent text-gray-900", "flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium"),
                    children: category.name
                  }, category.name))
                })
              }), /* @__PURE__ */ jsx(Tab.Panels, {
                as: Fragment$1,
                children: (_b = navigation$1.categories) == null ? void 0 : _b.map((category, categoryIdx) => {
                  var _a2, _b2, _c2, _d2;
                  return /* @__PURE__ */ jsx(Tab.Panel, {
                    className: "px-4 pt-10 pb-6 space-y-12",
                    children: /* @__PURE__ */ jsxs("div", {
                      className: "grid items-start grid-cols-1 gap-y-10 gap-x-6",
                      children: [/* @__PURE__ */ jsxs("div", {
                        className: "grid grid-cols-1 gap-y-10 gap-x-6",
                        children: [/* @__PURE__ */ jsxs("div", {
                          children: [/* @__PURE__ */ jsx("p", {
                            id: `mobile-featured-heading-${categoryIdx}`,
                            className: "font-medium text-gray-900",
                            children: "Featured"
                          }), /* @__PURE__ */ jsx("ul", {
                            role: "list",
                            "aria-labelledby": `mobile-featured-heading-${categoryIdx}`,
                            className: "mt-6 space-y-6",
                            children: (_a2 = category.featured) == null ? void 0 : _a2.map((item) => /* @__PURE__ */ jsx("li", {
                              className: "flex",
                              children: /* @__PURE__ */ jsx(Link, {
                                href: item.href,
                                className: "text-gray-500",
                                children: item.name
                              })
                            }, item.name))
                          })]
                        }), /* @__PURE__ */ jsxs("div", {
                          children: [/* @__PURE__ */ jsx("p", {
                            id: "mobile-categories-heading",
                            className: "font-medium text-gray-900",
                            children: "Categories"
                          }), /* @__PURE__ */ jsx("ul", {
                            role: "list",
                            "aria-labelledby": "mobile-categories-heading",
                            className: "mt-6 space-y-6",
                            children: (_b2 = category.categories) == null ? void 0 : _b2.map((item) => /* @__PURE__ */ jsx("li", {
                              className: "flex",
                              children: /* @__PURE__ */ jsx(Link, {
                                href: item.href,
                                className: "text-gray-500",
                                children: item.name
                              })
                            }, item.name))
                          })]
                        })]
                      }), /* @__PURE__ */ jsxs("div", {
                        className: "grid grid-cols-1 gap-y-10 gap-x-6",
                        children: [/* @__PURE__ */ jsxs("div", {
                          children: [/* @__PURE__ */ jsx("p", {
                            id: "mobile-collection-heading",
                            className: "font-medium text-gray-900",
                            children: "Collection"
                          }), /* @__PURE__ */ jsx("ul", {
                            role: "list",
                            "aria-labelledby": "mobile-collection-heading",
                            className: "mt-6 space-y-6",
                            children: (_c2 = category.collection) == null ? void 0 : _c2.map((item) => /* @__PURE__ */ jsx("li", {
                              className: "flex",
                              children: /* @__PURE__ */ jsx(Link, {
                                href: item.href,
                                className: "text-gray-500",
                                children: item.name
                              })
                            }, item.name))
                          })]
                        }), /* @__PURE__ */ jsxs("div", {
                          children: [/* @__PURE__ */ jsx("p", {
                            id: "mobile-brand-heading",
                            className: "font-medium text-gray-900",
                            children: "Brands"
                          }), /* @__PURE__ */ jsx("ul", {
                            role: "list",
                            "aria-labelledby": "mobile-brand-heading",
                            className: "mt-6 space-y-6",
                            children: (_d2 = category.brands) == null ? void 0 : _d2.map((item) => /* @__PURE__ */ jsx("li", {
                              className: "flex",
                              children: /* @__PURE__ */ jsx(Link, {
                                href: item.href,
                                className: "text-gray-500",
                                children: item.name
                              })
                            }, item.name))
                          })]
                        })]
                      })]
                    })
                  }, category.name);
                })
              })]
            }), /* @__PURE__ */ jsx("div", {
              className: "px-4 py-6 space-y-6 border-t border-gray-200",
              children: (_c = navigation$1.pages) == null ? void 0 : _c.map((page) => /* @__PURE__ */ jsx("div", {
                className: "flow-root",
                children: /* @__PURE__ */ jsx(Link, {
                  href: page.href,
                  className: "block p-2 -m-2 font-medium text-gray-900",
                  children: page.name
                })
              }, page.name))
            }), /* @__PURE__ */ jsx("div", {
              className: "px-4 py-6 space-y-6 border-t border-gray-200",
              children: /* @__PURE__ */ jsx("form", {
                children: /* @__PURE__ */ jsxs("div", {
                  className: "inline-block",
                  children: [/* @__PURE__ */ jsx("label", {
                    htmlFor: "mobile-currency",
                    className: "sr-only",
                    children: "Currency"
                  }), /* @__PURE__ */ jsxs("div", {
                    className: "relative -ml-2 border-transparent rounded-md group focus-within:ring-2 focus-within:ring-white",
                    children: [/* @__PURE__ */ jsx("select", {
                      id: "mobile-currency",
                      name: "currency",
                      className: "flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800",
                      children: currencies.map((currency) => /* @__PURE__ */ jsx("option", {
                        children: currency
                      }, currency))
                    }), /* @__PURE__ */ jsx("div", {
                      className: "absolute inset-y-0 right-0 flex items-center pointer-events-none",
                      children: /* @__PURE__ */ jsx("svg", {
                        "aria-hidden": "true",
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 20 20",
                        className: "w-5 h-5 text-gray-500",
                        children: /* @__PURE__ */ jsx("path", {
                          stroke: "currentColor",
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: "1.5",
                          d: "M6 8l4 4 4-4"
                        })
                      })
                    })]
                  })]
                })
              })
            })]
          })
        })]
      })
    }), /* @__PURE__ */ jsx("header", {
      className: "relative",
      children: /* @__PURE__ */ jsxs("nav", {
        "aria-label": "Top",
        children: [/* @__PURE__ */ jsx("div", {
          className: "bg-gray-900",
          children: /* @__PURE__ */ jsx("div", {
            className: "flex items-center justify-between lg:h-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8",
            children: /* @__PURE__ */ jsx("form", {
              className: "hidden lg:block lg:flex-1",
              children: /* @__PURE__ */ jsxs("div", {
                className: "flex",
                children: [/* @__PURE__ */ jsx("label", {
                  htmlFor: "desktop-currency",
                  className: "sr-only",
                  children: "Currency"
                }), /* @__PURE__ */ jsxs("div", {
                  className: "relative -ml-2 bg-gray-900 border-transparent rounded-md group focus-within:ring-2 focus-within:ring-white",
                  children: [/* @__PURE__ */ jsx("select", {
                    id: "desktop-currency",
                    name: "currency",
                    className: "flex items-center rounded-md border-transparent bg-gray-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100",
                    children: currencies.map((currency) => /* @__PURE__ */ jsx("option", {
                      children: currency
                    }, currency))
                  }), /* @__PURE__ */ jsx("div", {
                    className: "absolute inset-y-0 right-0 flex items-center pointer-events-none",
                    children: /* @__PURE__ */ jsx("svg", {
                      "aria-hidden": "true",
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 20 20",
                      className: "w-5 h-5 text-gray-300",
                      children: /* @__PURE__ */ jsx("path", {
                        stroke: "currentColor",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeWidth: "1.5",
                        d: "M6 8l4 4 4-4"
                      })
                    })
                  })]
                })]
              })
            })
          })
        }), /* @__PURE__ */ jsx("div", {
          className: "bg-white",
          children: /* @__PURE__ */ jsx("div", {
            className: "px-4 mx-auto max-w-7xl sm:px-6 lg:px-8",
            children: /* @__PURE__ */ jsx("div", {
              className: "border-b border-gray-200",
              children: /* @__PURE__ */ jsxs("div", {
                className: "flex items-center justify-between h-16",
                children: [/* @__PURE__ */ jsx("div", {
                  className: "hidden lg:flex lg:items-center",
                  "data-testid": "home-btn",
                  children: /* @__PURE__ */ jsxs(Link, {
                    href: "/",
                    children: [/* @__PURE__ */ jsx("span", {
                      className: "sr-only",
                      children: "Trip Shop"
                    }), /* @__PURE__ */ jsx("svg", {
                      fill: "none",
                      className: "w-8 h-8",
                      viewBox: "0 0 35 32",
                      xmlns: "http://www.w3.org/2000/svg",
                      children: /* @__PURE__ */ jsx("path", {
                        fill: "#4f46e5",
                        d: "M15.258 26.865a4.043 4.043 0 01-1.133 2.917A4.006 4.006 0 0111.253 31a3.992 3.992 0 01-2.872-1.218 4.028 4.028 0 01-1.133-2.917c.009-.698.2-1.382.557-1.981.356-.6.863-1.094 1.47-1.433-.024.109.09-.055 0 0l1.86-1.652a8.495 8.495 0 002.304-5.793c0-2.926-1.711-5.901-4.17-7.457.094.055-.036-.094 0 0A3.952 3.952 0 017.8 7.116a3.975 3.975 0 01-.557-1.98 4.042 4.042 0 011.133-2.918A4.006 4.006 0 0111.247 1a3.99 3.99 0 012.872 1.218 4.025 4.025 0 011.133 2.917 8.521 8.521 0 002.347 5.832l.817.8c.326.285.668.551 1.024.798.621.33 1.142.826 1.504 1.431a3.902 3.902 0 01-1.504 5.442c.033-.067-.063.036 0 0a8.968 8.968 0 00-3.024 3.183 9.016 9.016 0 00-1.158 4.244zM19.741 5.123c0 .796.235 1.575.676 2.237a4.01 4.01 0 001.798 1.482 3.99 3.99 0 004.366-.873 4.042 4.042 0 00.869-4.386 4.02 4.02 0 00-1.476-1.806 3.994 3.994 0 00-5.058.501 4.038 4.038 0 00-1.175 2.845zM23.748 22.84c-.792 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.806 4.042 4.042 0 00.869 4.387 3.99 3.99 0 004.366.873A4.01 4.01 0 0027.08 29.1a4.039 4.039 0 00-.5-5.082 4 4 0 00-2.832-1.18zM34 15.994c0-.796-.235-1.574-.675-2.236a4.01 4.01 0 00-1.798-1.483 3.99 3.99 0 00-4.367.873 4.042 4.042 0 00-.869 4.387 4.02 4.02 0 001.476 1.806 3.993 3.993 0 002.226.678 4.003 4.003 0 002.832-1.18A4.04 4.04 0 0034 15.993z M5.007 11.969c-.793 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.807 4.042 4.042 0 00.869 4.386 4.001 4.001 0 004.366.873 4.011 4.011 0 001.798-1.483 4.038 4.038 0 00-.5-5.08 4.004 4.004 0 00-2.831-1.181z"
                      })
                    })]
                  })
                }), /* @__PURE__ */ jsx("div", {
                  className: "hidden h-full lg:flex",
                  children: /* @__PURE__ */ jsx(Popover.Group, {
                    className: "ml-8",
                    children: /* @__PURE__ */ jsxs("div", {
                      className: "flex justify-center h-full space-x-8",
                      children: [(_d = navigation$1.categories) == null ? void 0 : _d.map((category, categoryIdx) => /* @__PURE__ */ jsx(Popover, {
                        className: "flex",
                        children: ({
                          open: open2
                        }) => {
                          var _a2, _b2, _c2, _d2;
                          return /* @__PURE__ */ jsxs(Fragment, {
                            children: [/* @__PURE__ */ jsx("div", {
                              className: "relative flex",
                              children: /* @__PURE__ */ jsx(Popover.Button, {
                                className: classNames(open2 ? "border-indigo-600 text-indigo-600" : "border-transparent text-gray-700 hover:text-gray-800", "relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out"),
                                children: category.name
                              })
                            }), /* @__PURE__ */ jsx(Transition, {
                              as: Fragment$1,
                              enter: "transition ease-out duration-200",
                              enterFrom: "opacity-0",
                              enterTo: "opacity-100",
                              leave: "transition ease-in duration-150",
                              leaveFrom: "opacity-100",
                              leaveTo: "opacity-0",
                              children: /* @__PURE__ */ jsxs(Popover.Panel, {
                                className: "absolute inset-x-0 z-10 text-gray-500 top-full sm:text-sm",
                                children: [/* @__PURE__ */ jsx("div", {
                                  className: "absolute inset-0 bg-white shadow top-1/2",
                                  "aria-hidden": "true"
                                }), /* @__PURE__ */ jsx("div", {
                                  className: "relative bg-white",
                                  children: /* @__PURE__ */ jsx("div", {
                                    className: "px-8 mx-auto max-w-7xl",
                                    children: /* @__PURE__ */ jsxs("div", {
                                      className: "grid items-start grid-cols-2 pt-10 pb-12 gap-y-10 gap-x-8",
                                      children: [/* @__PURE__ */ jsxs("div", {
                                        className: "grid grid-cols-2 gap-y-10 gap-x-8",
                                        children: [/* @__PURE__ */ jsxs("div", {
                                          children: [/* @__PURE__ */ jsx("p", {
                                            id: `desktop-featured-heading-${categoryIdx}`,
                                            className: "font-medium text-gray-900",
                                            children: "Featured"
                                          }), /* @__PURE__ */ jsx("ul", {
                                            role: "list",
                                            "aria-labelledby": `desktop-featured-heading-${categoryIdx}`,
                                            className: "mt-6 space-y-6 sm:mt-4 sm:space-y-4",
                                            children: (_a2 = category.featured) == null ? void 0 : _a2.map((item) => /* @__PURE__ */ jsx("li", {
                                              className: "flex",
                                              children: /* @__PURE__ */ jsx(Link, {
                                                href: item.href,
                                                className: "hover:text-gray-800",
                                                children: item.name
                                              })
                                            }, item.name))
                                          })]
                                        }), /* @__PURE__ */ jsxs("div", {
                                          children: [/* @__PURE__ */ jsx("p", {
                                            id: "desktop-categories-heading",
                                            className: "font-medium text-gray-900",
                                            children: "Categories"
                                          }), /* @__PURE__ */ jsx("ul", {
                                            role: "list",
                                            "aria-labelledby": "desktop-categories-heading",
                                            className: "mt-6 space-y-6 sm:mt-4 sm:space-y-4",
                                            children: (_b2 = category.categories) == null ? void 0 : _b2.map((item) => /* @__PURE__ */ jsx("li", {
                                              className: "flex",
                                              children: /* @__PURE__ */ jsx(Link, {
                                                href: item.href,
                                                className: "hover:text-gray-800",
                                                children: item.name
                                              })
                                            }, item.name))
                                          })]
                                        })]
                                      }), /* @__PURE__ */ jsxs("div", {
                                        className: "grid grid-cols-2 gap-y-10 gap-x-8",
                                        children: [/* @__PURE__ */ jsxs("div", {
                                          children: [/* @__PURE__ */ jsx("p", {
                                            id: "desktop-collection-heading",
                                            className: "font-medium text-gray-900",
                                            children: "Collection"
                                          }), /* @__PURE__ */ jsx("ul", {
                                            role: "list",
                                            "aria-labelledby": "desktop-collection-heading",
                                            className: "mt-6 space-y-6 sm:mt-4 sm:space-y-4",
                                            children: (_c2 = category.collection) == null ? void 0 : _c2.map((item) => /* @__PURE__ */ jsx("li", {
                                              className: "flex",
                                              children: /* @__PURE__ */ jsx(Link, {
                                                href: item.href,
                                                className: "hover:text-gray-800",
                                                children: item.name
                                              })
                                            }, item.name))
                                          })]
                                        }), /* @__PURE__ */ jsxs("div", {
                                          children: [/* @__PURE__ */ jsx("p", {
                                            id: "desktop-brand-heading",
                                            className: "font-medium text-gray-900",
                                            children: "Brands"
                                          }), /* @__PURE__ */ jsx("ul", {
                                            role: "list",
                                            "aria-labelledby": "desktop-brand-heading",
                                            className: "mt-6 space-y-6 sm:mt-4 sm:space-y-4",
                                            children: (_d2 = category.brands) == null ? void 0 : _d2.map((item) => /* @__PURE__ */ jsx("li", {
                                              className: "flex",
                                              children: /* @__PURE__ */ jsx(Link, {
                                                href: item.href,
                                                className: "hover:text-gray-800",
                                                children: item.name
                                              })
                                            }, item.name))
                                          })]
                                        })]
                                      })]
                                    })
                                  })
                                })]
                              })
                            })]
                          });
                        }
                      }, category.name)), (_e = navigation$1.pages) == null ? void 0 : _e.map((page) => /* @__PURE__ */ jsx(Link, {
                        href: page.href,
                        className: "flex items-center text-sm font-medium text-gray-700 hover:text-gray-800",
                        children: page.name
                      }, page.name))]
                    })
                  })
                }), /* @__PURE__ */ jsxs("div", {
                  className: "flex items-center flex-1 lg:hidden",
                  children: [/* @__PURE__ */ jsxs("button", {
                    type: "button",
                    className: "p-2 -ml-2 text-gray-400 bg-white rounded-md",
                    onClick: () => setOpen(true),
                    children: [/* @__PURE__ */ jsx("span", {
                      className: "sr-only",
                      children: "Open menu"
                    }), /* @__PURE__ */ jsx(MenuIcon, {
                      className: "w-6 h-6",
                      "aria-hidden": "true"
                    })]
                  }), /* @__PURE__ */ jsx(Link, {
                    href: "/search",
                    className: "p-2 ml-2 text-gray-400 hover:text-gray-500",
                    children: /* @__PURE__ */ jsxs(Fragment, {
                      children: [/* @__PURE__ */ jsx("span", {
                        className: "sr-only",
                        children: "Search"
                      }), /* @__PURE__ */ jsx(SearchIcon, {
                        className: "w-6 h-6",
                        "aria-hidden": "true"
                      })]
                    })
                  })]
                }), /* @__PURE__ */ jsxs(Link, {
                  href: "/",
                  className: "lg:hidden",
                  children: [/* @__PURE__ */ jsx("span", {
                    className: "sr-only",
                    children: "Workflow"
                  }), /* @__PURE__ */ jsx("svg", {
                    className: "w-8 h-8",
                    fill: "none",
                    viewBox: "0 0 35 32",
                    xmlns: "http://www.w3.org/2000/svg",
                    children: /* @__PURE__ */ jsx("path", {
                      fill: "#4f46e5",
                      d: "M15.258 26.865a4.043 4.043 0 01-1.133 2.917A4.006 4.006 0 0111.253 31a3.992 3.992 0 01-2.872-1.218 4.028 4.028 0 01-1.133-2.917c.009-.698.2-1.382.557-1.981.356-.6.863-1.094 1.47-1.433-.024.109.09-.055 0 0l1.86-1.652a8.495 8.495 0 002.304-5.793c0-2.926-1.711-5.901-4.17-7.457.094.055-.036-.094 0 0A3.952 3.952 0 017.8 7.116a3.975 3.975 0 01-.557-1.98 4.042 4.042 0 011.133-2.918A4.006 4.006 0 0111.247 1a3.99 3.99 0 012.872 1.218 4.025 4.025 0 011.133 2.917 8.521 8.521 0 002.347 5.832l.817.8c.326.285.668.551 1.024.798.621.33 1.142.826 1.504 1.431a3.902 3.902 0 01-1.504 5.442c.033-.067-.063.036 0 0a8.968 8.968 0 00-3.024 3.183 9.016 9.016 0 00-1.158 4.244zM19.741 5.123c0 .796.235 1.575.676 2.237a4.01 4.01 0 001.798 1.482 3.99 3.99 0 004.366-.873 4.042 4.042 0 00.869-4.386 4.02 4.02 0 00-1.476-1.806 3.994 3.994 0 00-5.058.501 4.038 4.038 0 00-1.175 2.845zM23.748 22.84c-.792 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.806 4.042 4.042 0 00.869 4.387 3.99 3.99 0 004.366.873A4.01 4.01 0 0027.08 29.1a4.039 4.039 0 00-.5-5.082 4 4 0 00-2.832-1.18zM34 15.994c0-.796-.235-1.574-.675-2.236a4.01 4.01 0 00-1.798-1.483 3.99 3.99 0 00-4.367.873 4.042 4.042 0 00-.869 4.387 4.02 4.02 0 001.476 1.806 3.993 3.993 0 002.226.678 4.003 4.003 0 002.832-1.18A4.04 4.04 0 0034 15.993z M5.007 11.969c-.793 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.807 4.042 4.042 0 00.869 4.386 4.001 4.001 0 004.366.873 4.011 4.011 0 001.798-1.483 4.038 4.038 0 00-.5-5.08 4.004 4.004 0 00-2.831-1.181z"
                    })
                  })]
                }), /* @__PURE__ */ jsx("div", {
                  className: "flex items-center justify-end flex-1",
                  children: /* @__PURE__ */ jsxs("div", {
                    className: "flex items-center lg:ml-8",
                    children: [/* @__PURE__ */ jsx("div", {
                      className: "flex space-x-8",
                      children: /* @__PURE__ */ jsx("div", {
                        className: "hidden lg:flex",
                        children: /* @__PURE__ */ jsxs(Link, {
                          href: "/search",
                          className: "p-2 -m-2 text-gray-400 hover:text-gray-500",
                          children: [/* @__PURE__ */ jsx("span", {
                            className: "sr-only",
                            children: "Search"
                          }), /* @__PURE__ */ jsx(SearchIcon, {
                            className: "w-6 h-6",
                            "aria-hidden": "true"
                          })]
                        })
                      })
                    }), /* @__PURE__ */ jsx("span", {
                      className: "w-px h-6 mx-4 bg-gray-200 lg:mx-6",
                      "aria-hidden": "true"
                    }), /* @__PURE__ */ jsx("div", {
                      className: "flow-root",
                      "data-testid": "cart-link",
                      children: /* @__PURE__ */ jsxs(Link, {
                        href: "/cart",
                        className: "flex items-center p-2 -m-2 group",
                        children: [/* @__PURE__ */ jsx(ShoppingCartIcon, {
                          className: "flex-shrink-0 w-6 h-6 text-gray-400 group-hover:text-gray-500",
                          "aria-hidden": "true"
                        }), /* @__PURE__ */ jsx("span", {
                          className: "ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800",
                          "data-testid": "cart-amount",
                          children: cartAmount != null ? cartAmount : 0
                        }), /* @__PURE__ */ jsx("span", {
                          className: "sr-only",
                          children: "items in cart, view bag"
                        })]
                      })
                    })]
                  })
                })]
              })
            })
          })
        })]
      })
    })]
  });
}
function FeaturedProducts({
  products
}) {
  return /* @__PURE__ */ jsxs("div", {
    className: "relative bg-white",
    children: [/* @__PURE__ */ jsxs("div", {
      "aria-hidden": "true",
      className: "absolute inset-0 hidden sm:flex sm:flex-col",
      children: [/* @__PURE__ */ jsxs("div", {
        className: "relative flex-1 w-full bg-gray-800",
        children: [/* @__PURE__ */ jsx("div", {
          className: "absolute inset-0 overflow-hidden",
          children: /* @__PURE__ */ jsx("img", {
            src: "/featured.jpg",
            alt: "",
            className: "object-cover object-center w-full h-full"
          })
        }), /* @__PURE__ */ jsx("div", {
          className: "absolute inset-0 bg-gray-900 opacity-50"
        })]
      }), /* @__PURE__ */ jsx("div", {
        className: "w-full h-32 bg-white md:h-40 lg:h-48"
      })]
    }), /* @__PURE__ */ jsxs("div", {
      className: "relative max-w-3xl px-4 mx-auto text-center pb-96 sm:px-6 sm:pb-0 lg:px-8",
      children: [/* @__PURE__ */ jsxs("div", {
        "aria-hidden": "true",
        className: "absolute inset-0 flex flex-col sm:hidden",
        children: [/* @__PURE__ */ jsxs("div", {
          className: "relative flex-1 w-full bg-gray-800",
          children: [/* @__PURE__ */ jsx("div", {
            className: "absolute inset-0 overflow-hidden",
            children: /* @__PURE__ */ jsx("img", {
              src: "/featured.jpg",
              alt: "",
              className: "object-cover object-center w-full h-full"
            })
          }), /* @__PURE__ */ jsx("div", {
            className: "absolute inset-0 bg-gray-900 opacity-50"
          })]
        }), /* @__PURE__ */ jsx("div", {
          className: "w-full h-48 bg-white"
        })]
      }), /* @__PURE__ */ jsx("div", {
        className: "relative py-16",
        children: /* @__PURE__ */ jsx("h1", {
          className: "text-2xl font-extrabold tracking-tight text-white sm:text-3xl md:text-4xl",
          children: "Featured Products"
        })
      })]
    }), /* @__PURE__ */ jsxs("section", {
      "aria-labelledby": "collection-heading",
      className: "relative -mt-96 sm:mt-0",
      children: [/* @__PURE__ */ jsx("h2", {
        id: "collection-heading",
        className: "sr-only",
        children: "Collections"
      }), /* @__PURE__ */ jsx("div", {
        className: "grid max-w-md grid-cols-1 px-4 mx-auto gap-y-6 sm:max-w-7xl sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 sm:px-6 lg:gap-x-8 lg:px-8",
        children: products.map((product) => /* @__PURE__ */ jsx("div", {
          className: "relative bg-white rounded-lg shadow-xl group h-96 sm:aspect-w-4 sm:aspect-h-5 sm:h-auto",
          children: /* @__PURE__ */ jsxs("div", {
            children: [/* @__PURE__ */ jsxs("div", {
              "aria-hidden": "true",
              className: "absolute inset-0 overflow-hidden rounded-lg",
              children: [/* @__PURE__ */ jsx("div", {
                className: "absolute inset-0 overflow-hidden group-hover:opacity-75",
                children: /* @__PURE__ */ jsx("img", {
                  loading: "lazy",
                  src: product.imageSrc,
                  alt: product.imageAlt,
                  className: "object-cover object-center w-full h-full"
                })
              }), /* @__PURE__ */ jsx("div", {
                className: "absolute inset-0 opacity-50 bg-gradient-to-b from-transparent to-black"
              })]
            }), /* @__PURE__ */ jsx("div", {
              className: "absolute inset-0 flex items-end p-6 rounded-lg",
              children: /* @__PURE__ */ jsx("div", {
                children: /* @__PURE__ */ jsx("h3", {
                  className: "mt-1 font-semibold text-white",
                  children: /* @__PURE__ */ jsxs(Link, {
                    href: `/product/${product.slug}`,
                    children: [/* @__PURE__ */ jsx("span", {
                      className: "absolute inset-0"
                    }), product.name]
                  })
                })
              })
            })]
          })
        }, product.name))
      })]
    })]
  });
}
const navigation = void 0;
function StoreFooter() {
  return /* @__PURE__ */ jsx("footer", {
    className: "bg-gray-50",
    children: /* @__PURE__ */ jsxs("div", {
      className: "px-4 py-12 mx-auto max-w-7xl sm:px-6 md:flex md:items-center md:justify-between lg:px-8",
      children: [/* @__PURE__ */ jsx("div", {
        className: "flex justify-center space-x-6 md:order-2",
        children: navigation == null ? void 0 : navigation.map((item) => /* @__PURE__ */ jsxs("a", {
          href: item.href,
          className: "text-gray-400 hover:text-gray-500",
          children: [/* @__PURE__ */ jsx("span", {
            className: "sr-only",
            children: item.name
          }), /* @__PURE__ */ jsx(item.icon, {
            className: "w-6 h-6",
            "aria-hidden": "true"
          })]
        }, item.name))
      }), /* @__PURE__ */ jsx("div", {
        className: "mt-8 md:order-1 md:mt-0",
        children: /* @__PURE__ */ jsx("p", {
          className: "text-base text-center text-gray-400",
          children: "\xA9 2022 Lennart Gastler"
        })
      })]
    })
  });
}
function Form(_c) {
  var _d = _c, {
    children,
    replace,
    action,
    method = "get"
  } = _d, rest = __objRest(_d, [
    "children",
    "replace",
    "action",
    "method"
  ]);
  const DefaultForm = /* @__PURE__ */ jsx("form", __spreadProps(__spreadValues({
    method,
    action
  }, rest), {
    children
  }));
  const {
    formComponent: FormComponent,
    framework
  } = useUiContext();
  if (FormComponent && framework === "remix") {
    return /* @__PURE__ */ jsx(FormComponent, __spreadProps(__spreadValues({
      replace,
      method,
      action
    }, rest), {
      children
    }));
  }
  return DefaultForm;
}
function ProductDetail({
  product,
  pathname,
  search,
  adding
}) {
  return /* @__PURE__ */ jsx("div", {
    className: "mx-auto pb-4 max-w-7xl sm:px-6 sm:py-16 lg:px-8",
    "data-testid": "product-detail",
    children: /* @__PURE__ */ jsxs("div", {
      className: "lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8",
      children: [/* @__PURE__ */ jsxs(Tab.Group, {
        as: "div",
        className: "flex flex-col-reverse",
        children: [/* @__PURE__ */ jsx("div", {
          className: "hidden w-full max-w-2xl mx-auto mt-6 sm:block lg:max-w-none",
          children: /* @__PURE__ */ jsx(Tab.List, {
            className: "grid grid-cols-4 gap-6",
            children: product.images.map((image) => /* @__PURE__ */ jsx(Tab, {
              className: "relative flex items-center justify-center h-24 text-sm font-medium text-gray-900 uppercase bg-white rounded-md cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4",
              children: ({
                selected
              }) => /* @__PURE__ */ jsxs(Fragment, {
                children: [/* @__PURE__ */ jsx("span", {
                  className: "absolute inset-0 overflow-hidden rounded-md",
                  children: /* @__PURE__ */ jsx("img", {
                    src: image.src,
                    alt: "",
                    className: "object-cover object-center w-full h-full"
                  })
                }), /* @__PURE__ */ jsx("span", {
                  className: classNames(selected ? "ring-indigo-500" : "ring-transparent", "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"),
                  "aria-hidden": "true"
                })]
              })
            }, image.src))
          })
        }), /* @__PURE__ */ jsx(Tab.Panels, {
          className: "w-full aspect-w-1 aspect-h-1",
          children: product.images.map((image) => /* @__PURE__ */ jsx(Tab.Panel, {
            children: /* @__PURE__ */ jsx("img", {
              src: image.src,
              className: "object-cover object-center w-full h-full sm:rounded-lg"
            })
          }, image.src))
        })]
      }), /* @__PURE__ */ jsxs("div", {
        className: "px-4 mt-10 sm:mt-16 sm:px-0 lg:mt-0",
        children: [/* @__PURE__ */ jsx("h1", {
          className: "text-3xl font-extrabold tracking-tight text-gray-900",
          children: product.name
        }), /* @__PURE__ */ jsxs("div", {
          className: "mt-3",
          children: [/* @__PURE__ */ jsx("h2", {
            className: "sr-only",
            children: "Product information"
          }), /* @__PURE__ */ jsx("p", {
            className: "text-3xl text-gray-900",
            children: product.price
          })]
        }), product.description ? /* @__PURE__ */ jsxs("div", {
          className: "mt-6",
          children: [/* @__PURE__ */ jsx("h3", {
            className: "sr-only",
            children: "Description"
          }), /* @__PURE__ */ jsx("div", {
            className: "space-y-6 text-base text-gray-700 prose",
            dangerouslySetInnerHTML: {
              __html: product.description
            }
          })]
        }) : null, /* @__PURE__ */ jsxs(Form, {
          replace: true,
          method: "post",
          className: "mt-6",
          id: "add-to-cart",
          children: [/* @__PURE__ */ jsx("input", {
            defaultValue: pathname + search,
            type: "hidden",
            name: "redirect"
          }, pathname + search), /* @__PURE__ */ jsx("input", {
            defaultValue: product.id,
            type: "hidden",
            name: "variantId"
          }, product.id), /* @__PURE__ */ jsx("button", {
            "data-testid": "add-to-cart-btn",
            className: classNames("flex items-center justify-center flex-1 max-w-xs px-8 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"),
            children: adding ? "Adding" : "Add to cart"
          })]
        })]
      })]
    })
  });
}
function RelatedProducts({
  relatedProducts
}) {
  return /* @__PURE__ */ jsxs("section", {
    "aria-labelledby": "related-heading",
    className: "px-4 py-16 mt-10 border-t border-gray-200 sm:px-0",
    children: [/* @__PURE__ */ jsx("h2", {
      id: "related-heading",
      className: "text-xl font-bold text-gray-900",
      children: "Customers also bought"
    }), /* @__PURE__ */ jsx("div", {
      className: "grid grid-cols-1 mt-8 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8",
      children: relatedProducts.map((product) => /* @__PURE__ */ jsxs("div", {
        children: [/* @__PURE__ */ jsxs("div", {
          className: "relative",
          children: [/* @__PURE__ */ jsx("div", {
            className: "relative w-full overflow-hidden rounded-lg h-72",
            children: /* @__PURE__ */ jsx("img", {
              src: product.imageSrc,
              alt: product.imageAlt,
              className: "object-cover object-center w-full h-full"
            })
          }), /* @__PURE__ */ jsx("div", {
            className: "relative mt-4",
            children: /* @__PURE__ */ jsx("h3", {
              className: "text-sm font-medium text-gray-900",
              children: product.name
            })
          }), /* @__PURE__ */ jsxs("div", {
            className: "absolute inset-x-0 top-0 flex items-end justify-end p-4 overflow-hidden rounded-lg h-72",
            children: [/* @__PURE__ */ jsx("div", {
              "aria-hidden": "true",
              className: "absolute inset-x-0 bottom-0 opacity-50 h-36 bg-gradient-to-t from-black"
            }), /* @__PURE__ */ jsx("p", {
              className: "relative text-lg font-semibold text-white",
              children: product.price
            })]
          })]
        }), /* @__PURE__ */ jsx("div", {
          className: "mt-6",
          children: /* @__PURE__ */ jsxs("a", {
            href: product.href,
            className: "relative flex items-center justify-center px-8 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200",
            children: ["Add to bag", /* @__PURE__ */ jsxs("span", {
              className: "sr-only",
              children: [", ", product.name]
            })]
          })
        })]
      }, product.id))
    })]
  });
}
function StoreSearch({
  term,
  products,
  loading
}) {
  return /* @__PURE__ */ jsxs("div", {
    className: "max-w-3xl px-4 pb-12 mx-auto sm:px-6 lg:max-w-7xl lg:px-8",
    children: [/* @__PURE__ */ jsxs("div", {
      className: "py-12 text-center",
      children: [/* @__PURE__ */ jsx("h1", {
        className: "text-4xl font-extrabold tracking-tight text-gray-900",
        children: "Search"
      }), /* @__PURE__ */ jsxs("div", {
        className: "w-full max-w-lg mx-auto mt-8 lg:max-w-xs",
        children: [/* @__PURE__ */ jsx("label", {
          htmlFor: "search",
          className: "sr-only",
          children: "Search"
        }), /* @__PURE__ */ jsxs("div", {
          className: "relative text-gray-400 focus-within:text-gray-500",
          children: [/* @__PURE__ */ jsx("div", {
            className: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none",
            children: /* @__PURE__ */ jsx(SearchIcon$1, {
              className: "w-5 h-5",
              "aria-hidden": "true"
            })
          }), /* @__PURE__ */ jsx(Form, {
            id: "search-form",
            children: /* @__PURE__ */ jsx("input", {
              id: "search",
              className: "block w-full py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-500 sm:text-sm",
              placeholder: "Search",
              type: "search",
              name: "q"
            })
          })]
        })]
      })]
    }), /* @__PURE__ */ jsxs("section", {
      "aria-labelledby": "products-heading",
      className: "mt-8",
      children: [/* @__PURE__ */ jsx("h2", {
        id: "products-heading",
        className: "sr-only",
        children: "Products"
      }), /* @__PURE__ */ jsx("div", {
        className: "grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8",
        children: loading ? /* @__PURE__ */ jsx(Fragment, {
          children: Array(6).fill("").map(() => /* @__PURE__ */ jsx("div", {
            className: "w-full overflow-hidden rounded-lg aspect-w-1 aspect-h-1 sm:aspect-w-2 sm:aspect-h-3 bg-slate-200 animate-pulse"
          }))
        }) : /* @__PURE__ */ jsx(Fragment, {
          children: products && products.length > 0 ? /* @__PURE__ */ jsx(Fragment, {
            children: products == null ? void 0 : products.map((product) => /* @__PURE__ */ jsxs(Link, {
              href: `/product/${product.slug}`,
              className: "group",
              children: [/* @__PURE__ */ jsx("div", {
                className: "w-full overflow-hidden rounded-lg aspect-w-1 aspect-h-1 sm:aspect-w-2 sm:aspect-h-3",
                children: /* @__PURE__ */ jsx("img", {
                  src: product.imageSrc,
                  alt: product.imageAlt,
                  className: "object-cover object-center w-full h-full group-hover:opacity-75"
                })
              }), /* @__PURE__ */ jsxs("div", {
                className: "flex items-center justify-between mt-4 text-base font-medium text-gray-900",
                children: [/* @__PURE__ */ jsx("h3", {
                  children: product.name
                }), /* @__PURE__ */ jsx("p", {
                  children: product.price
                })]
              })]
            }, product.id))
          }) : /* @__PURE__ */ jsxs("div", {
            className: "col-span-3 mx-auto text-center",
            children: [/* @__PURE__ */ jsx(EmojiSadIcon, {
              className: "w-12 h-12 mx-auto text-gray-400"
            }), /* @__PURE__ */ jsx("h3", {
              className: "mt-2 text-sm font-medium text-gray-900",
              children: "No results found!"
            }), /* @__PURE__ */ jsxs("p", {
              className: "mt-1 text-sm text-gray-500",
              children: ["There are no products that match ", /* @__PURE__ */ jsx("strong", {
                children: term
              })]
            })]
          })
        })
      })]
    })]
  });
}
function StoreLayout({
  cartAmount,
  children
}) {
  return /* @__PURE__ */ jsxs("div", {
    className: "flex flex-col min-h-screen",
    children: [/* @__PURE__ */ jsx(StoreHeader, {
      cartAmount
    }), /* @__PURE__ */ jsx("main", {
      className: "flex-1",
      children
    }), /* @__PURE__ */ jsx(StoreFooter, {})]
  });
}
function StoreCart({
  cart,
  checkoutEndpointUrl
}) {
  return /* @__PURE__ */ jsx("div", {
    className: "bg-white",
    "data-testid": "store-cart",
    children: /* @__PURE__ */ jsxs("div", {
      className: "max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8",
      children: [/* @__PURE__ */ jsx("h1", {
        className: "text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl",
        children: "Shopping Cart"
      }), /* @__PURE__ */ jsxs("div", {
        className: "mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16",
        children: [/* @__PURE__ */ jsxs("section", {
          "aria-labelledby": "cart-heading",
          className: "lg:col-span-7",
          children: [/* @__PURE__ */ jsx("h2", {
            id: "cart-heading",
            className: "sr-only",
            children: "Items in your shopping cart"
          }), cart ? /* @__PURE__ */ jsx("ul", {
            className: "border-t border-b border-gray-200 divide-y divide-gray-200",
            children: cart == null ? void 0 : cart.items.map((product, productIdx) => /* @__PURE__ */ jsxs("li", {
              className: "flex py-6 sm:py-10",
              children: [/* @__PURE__ */ jsx("div", {
                className: "flex-shrink-0",
                children: /* @__PURE__ */ jsx("img", {
                  src: product.info.image,
                  alt: "",
                  className: "w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                })
              }), /* @__PURE__ */ jsx("div", {
                className: "ml-4 flex-1 flex flex-col justify-between sm:ml-6",
                children: /* @__PURE__ */ jsxs("div", {
                  className: "relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0",
                  children: [/* @__PURE__ */ jsxs("div", {
                    children: [/* @__PURE__ */ jsx("div", {
                      className: "flex justify-between",
                      children: /* @__PURE__ */ jsx("h3", {
                        className: "text-sm",
                        children: /* @__PURE__ */ jsx(Link, {
                          href: `/product/${product.info.slug}`,
                          className: "font-medium text-gray-700 hover:text-gray-800",
                          children: product.info.title
                        })
                      })
                    }), /* @__PURE__ */ jsx("p", {
                      className: "mt-1 text-sm font-medium text-gray-900",
                      children: product.info.formattedPrice
                    })]
                  }), /* @__PURE__ */ jsxs("div", {
                    className: "mt-4 sm:mt-0 sm:pr-9",
                    children: [/* @__PURE__ */ jsxs("label", {
                      htmlFor: `quantity-${productIdx}`,
                      className: "sr-only",
                      children: ["Quantity, ", product.info.title]
                    }), /* @__PURE__ */ jsx("span", {
                      children: product.quantity
                    })]
                  }), /* @__PURE__ */ jsx(Form, {
                    method: "post",
                    action: "/cart",
                    id: "delete-item",
                    children: /* @__PURE__ */ jsxs("div", {
                      className: "absolute top-0 right-0",
                      children: [/* @__PURE__ */ jsx("input", {
                        type: "hidden",
                        name: "_action",
                        defaultValue: "delete"
                      }), /* @__PURE__ */ jsx("input", {
                        type: "hidden",
                        defaultValue: product.variantId,
                        name: "variantId"
                      }, product.variantId), /* @__PURE__ */ jsxs("button", {
                        type: "submit",
                        "data-testid": "remove-from-cart",
                        className: "-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500",
                        children: [/* @__PURE__ */ jsx("span", {
                          className: "sr-only",
                          children: "Remove from cart"
                        }), /* @__PURE__ */ jsx(XIcon, {
                          className: "h-5 w-5",
                          "aria-hidden": "true"
                        })]
                      })]
                    })
                  })]
                })
              })]
            }, product.variantId))
          }) : /* @__PURE__ */ jsx("ul", {
            className: "border-t border-b border-gray-200 divide-y divide-gray-200",
            children: Array(3).fill("").map((_, idx) => /* @__PURE__ */ jsxs("li", {
              className: "flex py-6 sm:py-10",
              children: [/* @__PURE__ */ jsx("div", {
                className: "w-24 h-24 rounded-md sm:w-48 sm:h-48 bg-slate-200 animate-pulse"
              }), /* @__PURE__ */ jsx("div", {
                className: "ml-4 flex-1 flex flex-col justify-between sm:ml-6",
                children: /* @__PURE__ */ jsx("div", {
                  className: "relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0",
                  children: /* @__PURE__ */ jsxs("div", {
                    children: [/* @__PURE__ */ jsx("div", {
                      className: "flex justify-between",
                      children: /* @__PURE__ */ jsx("div", {
                        className: "h-12 w-64 bg-slate-200 animate-pulse"
                      })
                    }), /* @__PURE__ */ jsx("p", {
                      className: "mt-1 text-sm font-medium text-gray-900"
                    })]
                  })
                })
              })]
            }, idx))
          })]
        }), /* @__PURE__ */ jsxs("section", {
          "aria-labelledby": "summary-heading",
          className: "mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5",
          children: [/* @__PURE__ */ jsx("h2", {
            id: "summary-heading",
            className: "text-lg font-medium text-gray-900",
            children: "Order summary"
          }), /* @__PURE__ */ jsx("dl", {
            className: "mt-6 space-y-4",
            children: /* @__PURE__ */ jsxs("div", {
              className: "border-t border-gray-200 pt-4 flex items-center justify-between",
              children: [/* @__PURE__ */ jsx("dt", {
                className: "text-base font-medium text-gray-900",
                children: "Order total"
              }), /* @__PURE__ */ jsx("dd", {
                className: "text-base font-medium text-gray-900",
                children: cart == null ? void 0 : cart.formattedSubTotal
              })]
            })
          }), /* @__PURE__ */ jsx(Form, {
            method: "post",
            action: checkoutEndpointUrl,
            className: "mt-8",
            id: "checkout",
            children: /* @__PURE__ */ jsx("div", {
              className: "mt-6",
              children: /* @__PURE__ */ jsx("button", {
                type: "submit",
                className: "w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500",
                children: "Checkout"
              })
            })
          })]
        })]
      })]
    })
  });
}
function CategoryPreview({
  categories
}) {
  return /* @__PURE__ */ jsx("div", {
    className: "bg-white",
    children: /* @__PURE__ */ jsxs("div", {
      className: "py-16 sm:py-24 xl:max-w-7xl xl:mx-auto xl:px-8",
      children: [/* @__PURE__ */ jsx("div", {
        className: "px-4 sm:px-6 sm:flex sm:items-center sm:justify-between lg:px-8 xl:px-0",
        children: /* @__PURE__ */ jsx("h2", {
          className: "text-2xl font-extrabold tracking-tight text-gray-900",
          children: "Shop by Category"
        })
      }), /* @__PURE__ */ jsx("div", {
        className: "mt-4 flow-root",
        children: /* @__PURE__ */ jsx("div", {
          className: "-my-2",
          children: /* @__PURE__ */ jsx("div", {
            className: "box-content py-2 relative h-80 overflow-x-auto xl:overflow-visible",
            children: /* @__PURE__ */ jsx("div", {
              className: "absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8",
              children: categories.map((category) => /* @__PURE__ */ jsxs(Link, {
                href: category.href,
                className: "relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto",
                children: [/* @__PURE__ */ jsx("span", {
                  "aria-hidden": "true",
                  className: "absolute inset-0",
                  children: /* @__PURE__ */ jsx("img", {
                    loading: "lazy",
                    src: category.imageSrc,
                    alt: "",
                    className: "w-full h-full object-center object-cover"
                  })
                }), /* @__PURE__ */ jsx("span", {
                  "aria-hidden": "true",
                  className: "absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                }), /* @__PURE__ */ jsx("span", {
                  className: "relative mt-auto text-center text-xl font-bold text-white",
                  children: category.name
                })]
              }, category.name))
            })
          })
        })
      })]
    })
  });
}
function StorePromo() {
  return /* @__PURE__ */ jsx("div", {
    className: "relative bg-white overflow-hidden",
    children: /* @__PURE__ */ jsx("div", {
      className: "pt-16 pb-80 sm:pt-24 lg:pb-16 lg:pt-16",
      children: /* @__PURE__ */ jsxs("div", {
        className: "relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static",
        children: [/* @__PURE__ */ jsxs("div", {
          className: "lg:max-w-lg",
          children: [/* @__PURE__ */ jsx("h1", {
            className: "text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl",
            children: "All amazing products"
          }), /* @__PURE__ */ jsx("p", {
            className: "mt-4 text-xl text-gray-500",
            children: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus saepe inventore, facilis asperiores facere. in commodi accusantium corrupti quis optio ipsa."
          })]
        }), /* @__PURE__ */ jsx("div", {
          children: /* @__PURE__ */ jsx("div", {
            className: "mt-10",
            children: /* @__PURE__ */ jsx("div", {
              "aria-hidden": "true",
              className: "pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full",
              children: /* @__PURE__ */ jsx("div", {
                className: "absolute transform lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8",
                children: /* @__PURE__ */ jsxs("div", {
                  className: "flex items-center space-x-6",
                  children: [/* @__PURE__ */ jsx("div", {
                    className: "flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8",
                    children: /* @__PURE__ */ jsx("div", {
                      className: "w-44 h-64 rounded-lg overflow-hidden lg:opacity-100",
                      children: /* @__PURE__ */ jsx("img", {
                        src: "/promo-1.jpg",
                        alt: "",
                        className: "w-full h-full object-center object-cover"
                      })
                    })
                  }), /* @__PURE__ */ jsx("div", {
                    className: "flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8",
                    children: /* @__PURE__ */ jsx("div", {
                      className: "w-44 h-64 rounded-lg overflow-hidden",
                      children: /* @__PURE__ */ jsx("img", {
                        src: "/promo-2.jpg",
                        alt: "",
                        className: "w-full h-full object-center object-cover"
                      })
                    })
                  }), /* @__PURE__ */ jsx("div", {
                    className: "flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8",
                    children: /* @__PURE__ */ jsx("div", {
                      className: "w-44 h-64 rounded-lg overflow-hidden",
                      children: /* @__PURE__ */ jsx("img", {
                        src: "/promo-3.jpg",
                        alt: "",
                        className: "w-full h-full object-center object-cover"
                      })
                    })
                  })]
                })
              })
            })
          })
        })]
      })
    })
  });
}
function StoreCheckoutDemo() {
  return /* @__PURE__ */ jsx("div", {
    className: "bg-white px-4 pt-16 pb-24 sm:px-6 sm:pt-24 lg:px-8 lg:py-32",
    children: /* @__PURE__ */ jsx("div", {
      className: "max-w-5xl mx-auto",
      children: /* @__PURE__ */ jsxs("div", {
        className: "max-w-xl",
        children: [/* @__PURE__ */ jsx("h1", {
          className: "text-sm font-semibold uppercase tracking-wide text-indigo-600",
          children: "DEMO!"
        }), /* @__PURE__ */ jsx("p", {
          className: "mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl",
          children: "Just an example page from here would be redirected to the actual checkout process.!"
        })]
      })
    })
  });
}
export { CategoryPreview, FeaturedProducts, MainLayout, ProductDetail, ProductList, RelatedProducts, StoreCart, StoreCheckoutDemo, StoreFooter, StoreHeader, StoreLayout, StorePromo, StoreSearch, Tweet, UiProvider, useUiContext };
